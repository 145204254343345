body::after {
  background: url("../img//about-bck.jpg");
  content: "";
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background-repeat: round;
}
.black-opacit {
  background-color: black;
  opacity: 0.5;
  margin-bottom: 5%;
  margin-top: 5%;
}
.btn-get {
  background-color: unset;
}
.btn-get:hover {
  background-color: #958677;
  color: rgb(241, 216, 192);
}
.heading-underline {
  color: black;
  font-size: 40px;
  font-weight: 600;
  /*margin-bottom: -40px;*/
  margin-top: 20px;
  /*width: 49%;*/
  border-bottom: 5px solid #958677;
  /*height: 57px;*/
  max-width: fit-content;
}
#hero .btn-get-start {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 17px;
  /* border-radius: 25px; */
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 0;
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .aboout-ph {
    text-align: left !important;
  }
  .logo-phone {
    margin-left: 55px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .para-title-ph {
    font-size: 20px !important;
    margin: auto;
  }
  .para-text-ph {
    font-size: 15px !important;
    text-align: center !important;
    margin-top: 2% !important;
  }
  .text-ph {
    font-size: 15px !important;
    text-align: center !important;
  }
  .sub-h-ph {
    font-size: 20px !important;
  }
  .section-mt-top {
    margin-top: -20%;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
  .logo-phone {
    margin-left: 33% !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .h2-tb {
    margin-top: 0% !important;
  }
  .carousel-container-tb {
    height: 35% !important;
    margin-top: 60px !important;
  }
  .section-mt-top {
    margin-top: -58% !important;
  }
  .carousel-container-a {
    height: 65% !important;
  }
  .carousel-img-tb {
    height: 450px !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .logo-phone {
    margin-left: -10px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .navbar-phone {
    margin-right: -35px !important;
  }
  .section-mt-top {
    margin-top: -70% !important;
  }
  .carousel-container-tb {
    height: 27% !important;
    margin-top: 60px !important;
  }
  .carousel-container-a {
    height: 67% !important;
  }
  .carousel-img-tb {
    height: 450px !important;
  }
  .nav-menu .dropdown ul {
    left: -20px !important;
  }
  .ul-ph {
    margin-left: 20px !important;
  }
  .ul-ph > li {
    right: 5px !important;
  }
}

@media only screen and (max-device-width: 1366px) and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .section-mt-top {
    margin-top: -30% !important;
  }
  .carousel-container-a {
    height: 35% !important;
    margin-top: 60px !important;
  }
  .carousel-container-tb {
    height: 35% !important;
    margin-top: 60px !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: portrait) {
  .section-mt-top {
    margin-top: -75% !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: landscape) {
  .nav-menu {
    margin-right: 0% !important;
  }
  .logo-phone {
    margin-left: 2% !important;
  }
  .section-mt-top {
    margin-top: -15% !important;
  }
  .carousel-container-a {
    height: 45% !important;
  }
  .carousel-container-tb {
    height: 45% !important;
  }
}
