.card-deck{
    height: 100% !important;
}

.col-lg-5{
    height: 100% !important;
}

body{
    /*    background-image:url(assets/img/service-background.jpg);*/
        display: block;
        position: relative;
        margin: 0 auto;
        z-index: 0;
        background-size: cover;
        background-attachment: fixed;
      }
      body::after {
        background: url(../img/about-bck.jpg);
        content: "";
        opacity: 0.7;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;   
      }
      .heading-underline{
        border-bottom: 5px solid;
        width: max-content;
        height: 38px;
        }
        .image-small{
          height: 400px;
          padding: 15px;
        }
        .btn-get{
        background-color: unset;
        }
        .btn-get:hover{
          background-color: rgb(149, 134, 119) ;
          color: white;
        }
        .div-box{
          background-color: rgb(149, 134, 119);
          height: auto;
        }
        .card-font{
          text-align: justify;font-family: 'Mulish';
          font-size: 17px;
          line-height: 2;
          font-weight: 300;
          color: rgb(68, 68, 68);
        }
        #card-list{
          text-align: justify;
          font-family: 'Libre Baskerville';
          font-size: 17px;
          font-weight: 300;
          color: rgb(68, 68, 68);
          margin-bottom: 4.5%
        }
  
  @media only screen 
      and (min-device-width: 360px) 
      and (max-device-width: 640px) 
      and (-webkit-min-device-pixel-ratio: 2)
      and (orientation: portrait){
      .aboout-ph{
          text-align: left !important;
        }
      .logo-phone{
         margin-left: 55px !important;
        }
      .section-title-phone{
        margin-left: 0px !important;
        }
      .para-title-ph{
        font-size: 20px !important;
        }
      .para-text-ph{
        font-size: 15px !important;
        }
      .carosal-text-ph{
        line-height: 1.5 !important;
        font-size: 15px !important;
        margin-right: 30px !important;
        margin-left: 30px !important;
        }
      .carosal-heading-ph{
        margin-bottom: -15px !important;
        }
      .carosal-no-img{
        display: none !important;
      }
      .cards-ph{
        width: 330px !important;
      }
      .section-margin{
        margin-top: -25% !important;
      }
      .sub-h-ph{
        font-size: 20px !important;
      }
      .text-ph{
        font-size: 15px !important;
        padding: 5px !important;
        text-align: center !important;
      }
      .heading-underline{
        height: 30px !important;
      }
      #card-list{
        font-size: 15px !important;
        text-align: left;
      }
      .card-text{
        font-size: 15px !important;
        line-height: 1.5;
        text-align: left;
      }
      .new-dd-ph{
      color: white;
      list-style: disc !important;
      }
      .ul-ph{
      margin-left: 17%;
      }
      .ul-ph > li{
        margin-bottom: -5%;
        position: relative;
        right: -15px;
        font-size: 15px;
      }
      }
  
  @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (-webkit-min-device-pixel-ratio: 2)
      and (orientation: portrait){
      .aboout-ph{
          text-align: left !important;
        }
      .logo-phone{
         margin-left: 32% !important;
        }
      .section-title-phone{
        margin-left: 0px !important;
      }
      .navbar-phone{
        margin-right: -35px !important; 
      }
      .carosal-heading-ph{
        margin-top: 1% !important;
        margin-bottom: -65px !important;
        margin-right: 31px;
        }
      .carosal-text-ph{
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-top: 65px !important;
        line-height: 1.5 !important;
      }
      .section-margin{
        margin-top: -60% !important;
      }
      .tab-ipad{
        display: none!important;
      }
      /*.col-md-7{
        display: none !important;
      }*/
      .new-dd-ph{
      color: white;
      list-style: disc !important;
      }
      .ul-ph{
      margin-left: 17%;
      }
      .ul-ph > li{
        margin-bottom: -5%;
        position: relative;
        right: -15px;
        font-size: 15px;
      }
     }
  
  @media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: portrait){
      .logo-phone{
           margin-left: -10px !important;
          }
      .tab-ipad{
        display: none!important;
      }
      .col-md-7{
        display: none !important;
    }
    .col-lg-7{
        display: none !important;
    }
    .carosal-text-ph {
      margin-left: -96% !important;
      margin-right: 0px !important;
      margin-top: 70px !important;
      line-height: 1.5 !important;
      position: relative;
      left: 50%;
      }
      .carosal-heading-ph {
        margin-top: 26% !important;
          margin-bottom: -65px !important;
          margin-right: 31px;
          margin-left: -10%;
      }
      .section-margin{
        margin-top: -75% !important;
      }
      .nav-menu .dropdown ul {
          left: -20px !important;
        }
        .ul-ph{
          margin-left: 20px !important;
        }
        .ul-ph > li {
          right: 5px !important;
        }
    }
  
  @media only screen  
  and (max-device-width: 1366px) 
  and (min-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape){
     .section-margin {
      margin-top: -30% !important;
      }
  }
  
  @media (min-device-width: 800px) 
    and (max-device-width: 1200px) 
    and (orientation: portrait){
     .section-margin{
      margin-top: -75% !important;
     }
  }
  
  @media (min-device-width: 800px) 
    and (max-device-width: 1200px) 
    and (orientation: landscape){
      .nav-menu{
        margin-right: 0% !important;
      }
      .logo-phone{
        margin-left: 2% !important;
      } 
     .section-margin{
      margin-top: -15% !important;
     }    
     .div-box{
      height: 468px !important;
     }
  }