/**
* Template Name: Mamba - v2.1.0
* Template URL: https://bootstrapmade.com/mamba-one-page-bootstrap-template-free/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    /*font-family: "Open Sans", sans-serif;*/
    /*font-family: "PT Serif",Arial,Helvetica,sans-serif;*/
    color: #444;
  }
  
  a {
    color: #428bca;
  }
  
  a:hover {
    color: #9eccf4;
    /*text-decoration: none;*/
  }
  
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    right: 15px;
    bottom: 15px;
    background: #18407c;
    color: #fff;
    transition: display 0.5s ease-in-out;
    z-index: 99999;
  }
  
  .back-to-top i {
    font-size: 24px;
    position: absolute;
    top: 8px;
    left: 8px;
  }
  
  .back-to-top:hover {
    color: #fff;
    background: #629fd3;
    transition: background 0.2s ease-in-out;
  }

  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    font-size: 14px;
  }
  
  #topbar .contact-info a {
    line-height: 1;
    color: #444;
    transition: 0.3s;
  }
  
  #topbar .contact-info a:hover {
    color: #428bca;
  }
  
  #topbar .contact-info i {
    color: #428bca;
    padding: 4px;
  }
  
  #topbar .contact-info .icofont-phone {
    padding-left: 20px;
    margin-left: 10px;
  }
  
  #topbar .social-links a {
    color: #5c768d;
    padding: 4px 12px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
  }
  
  #topbar .social-links a:hover {
    color: #428bca;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  .masthead {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 35rem;
    padding: 15rem 0;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 100%,
        rgba(0, 0, 0, 0) 75%,
        #000000 100%
      ),
      url("../img/home.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    filter: brightness(107%);
  }
  .masthead h1 {
    font-family: "Varela Round", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.8rem;
    background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .masthead h2 {
    max-width: 20rem;
    font-size: 1rem;
  }
  @media (min-width: 768px) {
    .masthead h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  @media (min-width: 992px) {
    .masthead {
      /*height: 100vh;*/
      padding: 0;
    }
    .masthead h1 {
      font-size: 6.5rem;
      line-height: 6.5rem;
      letter-spacing: 0.8rem;
    }
    .masthead h2 {
      max-width: 30rem;
      font-size: 1.25rem;
    }
  }
  
  #header {
    height: 70px;
    transition: all 0.5s;
    transition: all 0.5s;
    padding: 10px 0;
    z-index: 997;
  }
  
  #header.header-scrolled {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 10px 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  
  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: #1c5c93;
    text-decoration: none;
  }
  
  #header .logo img {
    padding: 0;
    margin: 0;
    width: 200px;
    height: 79px;
    margin-top: -21px;
    /*max-height: 54px;*/
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Desktop Navigation */
  .nav-menu,
  .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: bold;
  }
  
  .nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    float: left;
    margin-right: 20px;
  }
  
  .nav-menu a {
    display: block;
    position: relative;
    color: rgb(68, 68, 68);
    padding: 12px 15px;
    transition: 0.3s;
    font-size: 16px;
    /*font-family: "Open Sans", sans-serif;*/
    /*font-family: "Sarabun",Arial,Helvetica,sans-serif;*/
  }
  
  .nav-menu a:hover,
  .nav-menu .active > a,
  .nav-menu li:hover > a {
    color: #fff;
    text-decoration: none;
    background: rgb(149, 134, 119);
  }
  
  .nav-menu .dropdown ul {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    /*background: black;*/
    background: rgb(149, 134, 119);
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: ease all 0.3s;
  }
  
  .nav-menu .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .nav-menu .dropdown li {
    min-width: 180px;
    position: relative;
  }
  
  .nav-menu .dropdown ul a {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    color: black;
  }
  
  .nav-menu .dropdown ul a:hover,
  .nav-menu .dropdown ul .active > a,
  .nav-menu .dropdown ul li:hover > a {
    color: #fff;
  }
  
  .nav-menu .dropdown > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
  }
  
  .nav-menu .dropdown .dropdown ul {
    top: 0;
    left: 100%;
  }
  
  .nav-menu .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
  }
  
  .nav-menu .dropdown .dropdown > a {
    padding-right: 35px;
  }
  
  .nav-menu .dropdown .dropdown > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
  }
  
  @media (max-width: 1366px) {
    .nav-menu .dropdown .dropdown ul {
      left: -90%;
    }
    .nav-menu .dropdown .dropdown:hover > ul {
      left: -100%;
    }
    .nav-menu .dropdown .dropdown > a:after {
      content: "\ea9d";
    }
  }
  
  /* Mobile Navigation */
  .mobile-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 9999;
    overflow-y: auto;
    left: -260px;
    width: 260px;
    padding-top: 18px;
    background: #1f3548;
    transition: 0.4s;
  }
  
  .mobile-nav * {
    margin: 0;
    padding: 0;
    color: white;
    list-style: disc;
  }
  
  .mobile-nav a {
    display: block;
    position: relative;
    color: #e3f0fc;
    padding: 10px 20px;
    font-weight: 500;
  }
  
  .mobile-nav a:hover,
  .mobile-nav .active > a,
  .mobile-nav li:hover > a {
    color: #9eccf4;
    text-decoration: none;
  }
  
  .mobile-nav .dropdown > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 10px;
    position: absolute;
    right: 15px;
  }
  
  .mobile-nav .active.dropdown > a:after {
    content: "\eaa0";
  }
  
  .mobile-nav .dropdown > a {
    padding-right: 35px;
  }
  
  .mobile-nav .dropdown ul {
    display: none;
    overflow: hidden;
  }
  
  .mobile-nav .dropdown li {
    padding-left: 20px;
  }
  
  .mobile-nav-toggle {
    position: fixed;
    left: 15px;
    top: 20px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: left;
  }
  
  .mobile-nav-toggle i {
    color: #5c768d;
  }
  
  .mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(31, 53, 72, 0.7);
    overflow: hidden;
    display: none;
  }
  
  .mobile-nav-active {
    overflow: hidden;
  }
  
  .mobile-nav-active .mobile-nav {
    left: 0px;
  }
  
  .mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  #hero {
    margin-top: 15px;
    width: 100%;
    height: calc(100vh - 114px);
    padding: 0;
    overflow: hidden;
  }
  
  #hero .carousel-item {
    width: 100%;
    height: calc(100vh - 114px);
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    overflow: hidden;
  }
  #hero .carousel-item::before {
    content: "";
    background-color: rgba(13, 30, 45, 0.1);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
  }
  
  #hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
  
  #hero .carousel-content {
    text-align: left;
  }
  
  @media (max-width: 992px) {
    #hero,
    #hero .carousel-item {
      height: calc(100vh - 70px);
    }
    #hero .carousel-content.container {
      padding: 0 50px;
    }
  }
  
  #hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 74px;
    font-weight: 900;
  }
  
  #hero p {
    /*  width: 80%;*/
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
  }
  
  #hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
  }
  
  #hero .carousel-inner .carousel-item,
  #hero .carousel-inner .active.carousel-item-left,
  #hero .carousel-inner .active.carousel-item-right {
    opacity: 0;
  }
  
  #hero .carousel-inner .active,
  #hero .carousel-inner .carousel-item-next.carousel-item-left,
  #hero .carousel-inner .carousel-item-prev.carousel-item-right {
    /*opacity: 0.7;*/
    transition: 0.5s;
    opacity: 1;
  }
  
  #hero .carousel-inner .carousel-item-next,
  #hero .carousel-inner .carousel-item-prev,
  #hero .carousel-inner .active.carousel-item-left,
  #hero .carousel-inner .active.carousel-item-right {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
  
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 10%;
  }
  
  #hero .carousel-control-next-icon,
  #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
  }
  
  #hero .carousel-indicators li {
    cursor: pointer;
  }
  
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 17px;
    /* border-radius: 25px;*/
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 0;
    background: rgba(149, 134, 119, 70%);
    /*opacity: 0.7;*/
  }
  
  #hero .btn-get-started:hover {
    background: rgb(149, 134, 119);
  }
  
  @media (max-width: 768px) {
    #hero h2 {
      font-size: 28px;
    }
  }
  
  @media (min-width: 1024px) {
    /*#hero p {
      width: 60%;
    }*/
    #hero .carousel-control-prev,
    #hero .carousel-control-next {
      width: 5%;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 0px 0;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5c768d;
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  .cardd {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* background-color: #fff; */
    background-clip: border-box;
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: 0.25rem;
  }
  /*--------------------------------------------------------------
  # About Us
  --------------------------------------------------------------*/
  .about {
    padding-bottom: 30px;
  }
  
  .about .container {
    box-shadow: 0 5px 25px 0 rgba(214, 215, 216, 0.6);
  }
  
  .about .video-box img {
    padding: 15px 0;
  }
  
  .about .section-title p {
    text-align: left;
    font-style: italic;
    color: #666;
  }
  
  .about .about-content {
    padding: 40px;
  }
  
  .about .icon-box + .icon-box {
    margin-top: 40px;
  }
  
  .about .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    background: #f1f7fb;
    border-radius: 6px;
    transition: 0.5s;
  }
  
  .about .icon-box .icon i {
    color: #428bca;
    font-size: 32px;
  }
  
  .about .icon-box:hover .icon {
    background: #428bca;
  }
  
  .about .icon-box:hover .icon i {
    color: #fff;
  }
  
  .about .icon-box .title {
    margin-left: 95px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  
  .about .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  
  .about .icon-box .title a:hover {
    color: #428bca;
  }
  
  .about .icon-box .description {
    margin-left: 95px;
    line-height: 24px;
    font-size: 14px;
  }
  
  .about .video-box {
    position: relative;
  }
  
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#428bca 50%, rgba(66, 139, 202, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  
  .about .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .about .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(66, 139, 202, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  
  .about .play-btn:hover::after {
    border-left: 15px solid #428bca;
    transform: scale(20);
  }
  
  .about .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Clients
  --------------------------------------------------------------*/
  
  /*--------------------------------------------------------------
  # About Lists
  --------------------------------------------------------------*/
  .about-lists {
    padding: 40px;
  }
  
  .about-lists .row {
    overflow: hidden;
  }
  
  .about-lists .content-item {
    padding: 40px;
    border-left: 1px solid #d9e8f4;
    border-bottom: 1px solid #d9e8f4;
    margin: -1px;
  }
  
  .about-lists .content-item span {
    display: block;
    font-size: 24px;
    font-weight: 400;
    color: #9eccf4;
  }
  
  .about-lists .content-item h4 {
    font-size: 28px;
    font-weight: 400;
    padding: 0;
    margin: 20px 0;
  }
  
  .about-lists .content-item p {
    color: #aaaaaa;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }
  
  @media (max-width: 768px) {
    .about-lists .content-item {
      padding: 40px 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  .counts {
    padding-bottom: 30px;
  }
  
  .counts .count-box {
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 30px;
    background: #fff;
    margin-bottom: 30px;
  }
  
  .counts .count-box i {
    display: block;
    font-size: 64px;
    margin-bottom: 15px;
  }
  
  .counts .count-box span {
    font-size: 42px;
    display: block;
    font-weight: 700;
    color: #1c5c93;
  }
  
  .counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services {
    padding-bottom: 30px;
  }
  
  .services .icon-box {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .services .icon {
    display: flex;
    justify-content: center;
  }
  
  .services .icon i {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.5s;
    color: #428bca;
    font-size: 40px;
    overflow: hidden;
    padding-top: 20px;
    box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
  }
  
  .services .icon-box:hover .icon i {
    box-shadow: 0px 0 30px rgba(66, 139, 202, 0.5);
  }
  
  .services .title {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 18px;
    position: relative;
    padding-bottom: 15px;
  }
  
  .services .title a {
    color: #444;
    transition: 0.3s;
  }
  
  .services .title a:hover {
    color: #428bca;
  }
  
  .services .title::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    background: #428bca;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .services .description {
    line-height: 24px;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Our Portfolio
  --------------------------------------------------------------*/
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
  
  .portfolio #portfolio-flters {
    padding: 0;
    margin: 0 0 35px 0;
    list-style: none;
    text-align: center;
  }
  
  .portfolio #portfolio-flters li {
    cursor: pointer;
    margin: 0 15px 15px 0;
    display: inline-block;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
    color: #444;
    border-radius: 4px;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .portfolio #portfolio-flters li:hover,
  .portfolio #portfolio-flters li.filter-active {
    background: #428bca;
    color: #fff;
  }
  
  .portfolio #portfolio-flters li:last-child {
    margin-right: 0;
  }
  
  .portfolio .portfolio-wrap {
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    transition: 0.3s;
    position: relative;
    overflow: hidden;
  }
  
  .portfolio .portfolio-wrap img {
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s;
    text-align: center;
    background: rgba(31, 53, 72, 0.6);
    padding-bottom: 30px;
  }
  
  .portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
  }
  
  .portfolio .portfolio-wrap .portfolio-info p {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  .portfolio .portfolio-wrap .portfolio-info a {
    color: #428bca;
    margin: 0 4px;
    font-size: 18px;
    line-height: 1;
    background-color: #fff;
    padding-top: 7px;
    border-radius: 50px;
    text-align: center;
    width: 36px;
    height: 35px;
    display: inline-block;
    transition: 0.3s;
  }
  
  .portfolio .portfolio-wrap .portfolio-info a:hover {
    background: #428bca;
    color: #fff;
  }
  
  .portfolio .portfolio-wrap:hover {
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
  }
  
  .portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
    padding-bottom: 0;
  }
  
  .portfolio .portfolio-wrap:hover img {
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------------------
  # Our Team
  --------------------------------------------------------------*/
  .team {
    background: #fff;
    padding: 60px 0 30px 0;
  }
  
  .team .member {
    text-align: center;
    margin-bottom: 80px;
    position: relative;
  }
  
  .team .member .pic {
    border-radius: 4px;
    overflow: hidden;
  }
  
  .team .member img {
    transition: all ease-in-out 0.4s;
  }
  
  .team .member:hover img {
    transform: scale(1.1);
  }
  
  .team .member .member-info {
    position: absolute;
    bottom: -48px;
    left: 20px;
    right: 20px;
    background: linear-gradient(
      360deg,
      #5c768d 0%,
      rgba(92, 118, 141, 0.9) 35%,
      rgba(140, 167, 191, 0.8) 100%
    );
    padding: 15px 0;
    border-radius: 4px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
    color: #fff;
    position: relative;
    padding-bottom: 10px;
  }
  
  .team .member h4::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #fff;
    bottom: 0;
    left: calc(50% - 25px);
  }
  
  .team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
  }
  
  .team .member .social {
    margin-top: 15px;
  }
  
  .team .member .social a {
    transition: color 0.3s;
    color: #fff;
  }
  
  .team .member .social a:hover {
    color: #9eccf4;
  }
  
  .team .member .social i {
    font-size: 16px;
    margin: 0 2px;
  }
  
  @media (max-width: 992px) {
    .team .member {
      margin-bottom: 100px;
    }
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq {
    padding-bottom: 30px;
  }
  
  .faq .faq-item {
    margin-bottom: 40px;
  }
  
  .faq .faq-item h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #1f3548;
  }
  
  /*--------------------------------------------------------------
  # Contact Us
  --------------------------------------------------------------*/
  .contact .info-box {
    color: #444;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
    margin-bottom: 30px;
    width: 100%;
  }
  
  .contact .info-box i {
    font-size: 32px;
    color: #428bca;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #9eccf4;
  }
  
  .contact .info-box h3 {
    font-size: 20px;
    color: #666;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .contact .php-email-form {
    /* box-shadow: 0 0 30px rgba(110, 110, 110, 0.6);*/
    padding: 30px;
  }
  
  .contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  
  .contact .php-email-form input::focus,
  .contact .php-email-form textarea::focus {
    background-color: #428bca;
  }
  
  .contact .php-email-form input {
    padding: 20px 15px;
  }
  
  .contact .php-email-form textarea {
    /*margin-bottom: 40px;*/
    padding: 4px 19px;
  }
  
  .contact .php-email-form button[type="submit"] {
    background: rgb(22, 89, 148);
    border: 0;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
  }
  
  .contact .php-email-form button[type="submit"]:hover {
    background: rgb(149, 134, 119);
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  #footer {
    background: #165994;
    /*padding: 0 0 30px 0;*/
    color: #fff;
    font-size: 14px;
  }
  
  #footer .footer-top {
    background: #5c768d;
    border-top: 1px solid #768fa6;
    border-bottom: 1px solid #67839c;
    padding: 60px 0 30px 0;
  }
  
  #footer .footer-top .footer-info {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
  }
  
  #footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #18407c;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footer .footer-top .social-links a:hover {
    background: #428bca;
    color: #fff;
    text-decoration: none;
  }
  
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #9eccf4;
    font-size: 18px;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footer .footer-top .footer-links ul a:hover {
    color: #9eccf4;
  }
  
  #footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4;
  }
  
  #footer .footer-top .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footer .footer-top .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #428bca;
    color: #fff;
    transition: 0.3s;
    border-radius: 4;
  }
  
  #footer .footer-top .footer-newsletter form input[type="submit"]:hover {
    background: #5295ce;
  }
  
  #footer .copyright {
    text-align: center;
    padding-top: 8px;
  }
  
  #footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footer .credits a {
    color: #9eccf4;
  }
  
  /* Button used to open the chat form - fixed at the bottom of the page */
  .open-button {
    background-color: #18407c;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 14px;
    left: 24px;
    width: 60px;
    height: 60px;
    border-radius: 35px;
    z-index: 99999;
  }
  
  /* The popup chat - hidden by default */
  .chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    left: 15px;
    bottom: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
  }
  
  /* Add styles to the form container */
  .form-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
  }
  
  /* Full-width textarea */
  .form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
  }
  
  /* When the textarea gets focus, do something */
  .form-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Set a style for the submit/send button */
  .form-container .btn {
    background-color: #4caf50;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
  }
  
  /* Add a red background color to the cancel button */
  .form-container .cancel {
    background-color: red;
  }
  
  /* Add some hover effects to buttons */
  .form-container .btn:hover,
  .open-button:hover {
    opacity: 1;
  }
  @media (max-width: 480px) {
    .social-link {
      margin-left: 80px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .social-link {
      margin-left: 39%;
    }
  }
  
  .ro-spac {
    margin-top: 3%;
  }
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 22px;
    font-size: xx-large;
    color: white;
  }
  .button-position {
    position: absolute;
    top: 46%;
    right: 41%;
    background-color: #996515;
    border-radius: 20px;
    width: 93px;
    height: 37px;
    text-align: center;
    padding-top: 7px;
    font-weight: bold;
  }
  .border-color {
    box-shadow: 0 0 30px rgba(110, 110, 110, 0.6);
    width: 100%;
    height: 100%;
  
    /*border: 7px solid #533;*/
  }
  .link-color {
    color: white;
  }
  /*.zoom {
            transition: transform .2s;
            width: 200px;
            height: 200px;
            margin: 0 auto;
          }*/
  /*
          .zoom:hover {
            color: blue;
            -ms-transform: scale(1.1);
            -webkit-transform: scale(1.1); 
            transform: scale(1.1); 
          }*/
  .image-height {
    height: 50px !important;
    width: inherit;
  }
  .decor-text {
    text-align: center;
    font-size: 17px;
    font-family: "Mulish";
    font-weight: 300;
    color: rgb(68, 68, 68);
    line-height: 2;
  }
  .button {
    display: none;
  }
  .wrapper:hover .button {
    display: inline-block;
  }
  
  /* Button used to open the chat form - fixed at the bottom of the page */
  .open-button {
    background-color: #428bca;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    left: 28px;
    width: 60px;
    height: 60px;
    border-radius: 35px;
    z-index: 99999;
  }
  
  /* The popup chat - hidden by default */
  .chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    left: 15px;
    bottom: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
  }
  
  /* Add styles to the form container */
  .form-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
  }
  
  /* Full-width textarea */
  .form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
  }
  
  /* When the textarea gets focus, do something */
  .form-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Set a style for the submit/send button */
  .form-container .btn {
    background-color: #4caf50;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
  }
  
  /* Add a red background color to the cancel button */
  .form-container .cancel {
    background-color: red;
  }
  
  /* Add some hover effects to buttons */
  .form-container .btn:hover,
  .open-button:hover {
    opacity: 1;
  }
  .fa-lg {
    font-size: 6.333333em;
    line-height: 0.75em;
    vertical-align: 0%;
  }
  @media (max-width: 480px) {
    .social-link {
      margin-left: 80px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .social-link {
      margin-left: 39%;
    }
  }
  .icon-size {
    width: 50%;
    height: auto;
  }
  .h-bold {
    font-weight: 600;
    margin-bottom: 30px;
  }
  
  /*-----------Heading Fonts-------------------------------*/
  .main-heading {
    font-family: "Book Antiqua", arial;
  }
  .sub-heading {
    font-family: "Book Antiqua", arial;
  }
  .p-text {
    font-family: CenturyGothic;
  }
  /*-----------Heading Fonts-------------------------------*/
  
  /* Button used to open the chat form - fixed at the bottom of the page */
  .open-button {
    background-color: #428bca;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    left: 28px;
    width: 60px;
    height: 60px;
    border-radius: 35px;
    z-index: 99999;
  }
  
  /* The popup chat - hidden by default */
  .chat-popup {
    display: none;
    position: fixed;
    bottom: 0;
    left: 15px;
    bottom: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
  }
  
  /* Add styles to the form container */
  .form-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
  }
  
  /* Full-width textarea */
  .form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
  }
  
  /* When the textarea gets focus, do something */
  .form-container textarea:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Set a style for the submit/send button */
  .form-container .btn {
    background-color: #4caf50;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
  }
  
  /* Add a red background color to the cancel button */
  .form-container .cancel {
    background-color: red;
  }
  
  /* Add some hover effects to buttons */
  .form-container .btn:hover,
  .open-button:hover {
    opacity: 1;
  }
  @media (max-width: 480px) {
    .social-link {
      margin-left: 80px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .social-link {
      margin-left: 39%;
    }
  }
  /*.table thead tr th, .table tbody tr td {
      border: none;
            }*/
  table td:nth-of-type(1) {
    border: none !important;
    border-right: solid 1px black !important;
    border: 0;
  }
  .table td,
  th {
    vertical-align: middle;
    border-top: 1px solid #ffffff00;
  }
  .counts .count-box {
    padding-bottom: 50%;
    background-color: white;
    opacity: 0.4;
  }
  .cardd {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 6%;
  }
  .pull_val {
    padding-top: 40%;
    font-size: 75px;
    font-weight: bolder;
  }
  @media (max-width: 480px) {
  }
  @media (min-width: 481px) and (max-width: 767px) {
  }
  .no-btn {
    background-color: #18407c;
    /* width: 12%;
                height: 29px;*/
    color: white;
    border-radius: 20px;
    padding: 10px;
  }
  .btn-get-started {
    font-family: "Mulish";
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 25px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 0;
    background: rgb(149, 134, 119);
  }
  .btn-get-started:hover {
    background-color: rgb(68, 68, 68);
    color: white;
  }
  