body {
  /*    background-image:url(assets/img/service-background.jpg);*/
  display: block;
  position: relative;
  margin: 0 auto;
  z-index: 0;
  background-size: cover;
  background-attachment: fixed;
}

.contact_container {
  display: flex;
  width: 100%;
}
body::after {
  background: url("../img/background-1.jpg");
  content: "";
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.btn-get {
  background-color: unset;
}
.btn-get:hover {
  background-color: #958677;
  color: white;
}
.heading-underline {
  border-bottom: 5px solid;
  width: max-content;
  height: 39px;
  color: rgb(68, 68, 68);
}

.wrapper {
  margin-top: 10px;
  position: relative;
  width: 90%;
  margin: 0px auto;
  height: 300px;
}
#map {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 250px;
}
.right {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  height: 300px;
}
.form-height {
  height: 90%;
  width: 100%;
}
@media (min-width: 481px) and (max-width: 767px) {
  .img-phone {
    width: 100% !important;
  }
  .paddin {
    padding-right: 0px;
    padding-left: 0px;
  }
  .row-paddin {
    margin-left: 1%;
    margin-right: 1%;
  }
}

@media (max-width: 480px) {
  .img-phone {
    width: 100% !important;
    height: 225px !important;
  }
  .paddin {
    padding: 0px;
  }
  .row-paddin {
    margin-left: 1%;
    margin-right: 1%;
    margin-top: -25%;
    margin-bottom: -15%;
  }
  .text-phon {
    font-size: 10vw !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .logo-phone {
    margin-left: 55px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .address-ph {
    text-align: left !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .address-ph-a {
    margin-left: 10% !important;
  }
  .no-img-ph {
    display: none !important;
  }
  .sub-hd-ph {
    font-size: 20px !important;
  }
  .text-ph {
    font-size: 15px !important;
  }
  .heading-underline {
    height: 20px !important;
    padding-bottom: 22px !important;
  }
  .footer-ph {
    font-size: 13px;
    margin-top: 5px;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
  .rm-bg-ph {
    box-shadow: unset !important;
  }
  .rm-map-ph {
    display: none !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .mr-tb {
    margin-top: 0% !important;
    margin-bottom: -3%;
  }
  .logo-phone {
    margin-left: 32% !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .no-img-ph {
    display: none !important;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .logo-phone {
    margin-left: 0% !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .navbar-phone {
    margin-right: -35px !important;
  }
  .form-height {
    height: 91%;
    width: 200%;
  }
  .mr-tb {
    margin-top: 3% !important;
    margin-bottom: 6%;
  }
  .no-img-ph {
    display: none !important;
  }
  .mr-tp-tb {
    margin-top: 5%;
  }
  .nav-menu .dropdown ul {
    left: -20px !important;
  }
  .ul-ph {
    margin-left: 20px !important;
  }
  .ul-ph > li {
    right: 5px !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: portrait) {
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: landscape) {
  .nav-menu {
    margin-right: 0% !important;
  }
  .logo-phone {
    margin-left: 2% !important;
  }
  .no-img-ph {
    display: block !important;
  }
  .form-height {
    height: 85% !important;
    width: 100% !important;
  }
}
