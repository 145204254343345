body {
  /*    background-image:url(assets/img/service-background.jpg);*/
  display: block;
  position: relative;
  margin: 0 auto;
  z-index: 0;
  background-size: cover;
  background-attachment: fixed;
}
body::after {
  background: url(../img/about-bck.jpg);
  content: "";
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background-repeat: round;
}
/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #428bca;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  left: 28px;
  width: 60px;
  height: 60px;
  border-radius: 35px;
  z-index: 99999;
}
.list-style {
  margin: 14%;
  margin-left: 22%;
  color: white;
  font-size: 18px;
  font-family: "Mulish";
  font-weight: 300;
  text-align: left;
}
.heading-underline {
  border-bottom: 5px solid rgb(149, 134, 119);
  max-width: fit-content;
  height: 44px;
}
.list-style li {
  margin-bottom: 30px;
}
.list-style li a {
  color: white;
}

/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  left: 15px;
  bottom: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}
.div-box {
  background-color: rgb(22, 89, 148) !important;
  height: auto !important;
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}
.sinewave {
  background-image: url(../img/sinewave-70.png);
  background-repeat: no-repeat;
  background-position: 0px 30px;
  /*background-attachment: fixed;*/
  background-size: 100% 50%;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .aboout-ph {
    text-align: left !important;
  }
  .logo-phone {
    margin-left: 55px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .social-link {
    margin-left: 80px;
  }
  .gif-right {
    right: 0px !important;
  }
  .para-title-ph {
    font-size: 19px !important;
  }
  .para-text-ph {
    margin-top: 0% !important;
    font-size: 15px !important;
    text-align: center !important;
    margin-left: 0% !important;
  }
  .text-marg-ph {
    margin-left: 26% !important;
  }
  .hide-gif-ph {
    display: none !important;
  }
  .text-ph {
    font-size: 15px !important;
  }
  .sub-h-ph {
    font-size: 20px !important;
  }
  .sub-ph {
    font-size: 28px !important;
  }
  .section-mt-top {
    margin-top: -65%;
  }
  .heading-adj {
    height: 28px !important;
    margin-left: 0% !important;
  }
  .btn-mr {
    margin-top: -5px !important;
    margin-bottom: 15%;
  }
  .icon-size {
    width: 30% !important;
    margin-bottom: -5%;
  }
  .list-style {
    margin-left: 12% !important;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
  .tab-underline {
    height: 30px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .logo-phone {
    margin-left: 32% !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
  .hide-gif-ph {
    display: none !important;
  }
  .col-md-5-mr {
    margin-left: -5%;
  }
  .sub-ph {
    font-size: 31px !important;
    margin-bottom: -5% !important;
    margin-top: 10% !important;
    position: relative;
    left: 75%;
  }
  .list-style {
    font-size: 17px !important;
    margin-left: -13% !important;
    right: -95%;
    position: relative;
  }
  .icon-size {
    display: none !important;
  }
  .icon-xtra {
    margin-top: 0% !important;
  }
  .text-mr-bt {
    margin-bottom: 5%;
  }
  /*.sinewave{
            background-image: unset !important;
          }*/
  .mr-tp-ph-a {
    margin-top: -13%;
  }
  .mr-tp-ph-b {
    margin-top: -7%;
  }
  .section-mt-top {
    margin-top: -80% !important;
  }
  .para-title-tb {
    margin-left: 32% !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .logo-phone {
    margin-left: -10px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .navbar-phone {
    margin-right: -35px !important;
  }
  .div-box {
    background-color: rgb(22, 89, 148) !important;
    height: auto !important;
  }
  .section-title-a h2 {
    text-align: center;
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #f8f9fa;
  }
  .section-title-b h2 {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
    color: rgb(22, 89, 148);
  }
  .section-title-c h3 {
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
  }
  .section-title-d h2 {
    text-align: center;
    font-size: ;
    font-weight: 500;
    font-family: "Times New Roman", Times, serif;
    color: rgb(22, 89, 148);
  }
  .list-style {
    margin-left: 1% !important;
    margin-top: 10% !important;
    right: -78% !important;
    position: relative;
    list-style: none !important;
    text-align: center !important;
  }
  .list-style li {
    margin-bottom: 30px;
  }
  .list-style li a {
    color: white;
  }
  .icon-size {
    width: 40%;
    height: auto;
  }
  .heading-underline {
    text-align: center;
    border-bottom: 5px solid;
    width: max-content;
    height: 40px;
    margin-top: -1%;
  }
  .btn-get {
    background-color: unset;
  }
  .btn-get:hover {
    background-color: rgb(149, 134, 119);
    color: rgb(241, 216, 192);
  }
  .btn:hover {
    background: rgb(68, 68, 68);
    /* text-decoration: none; */
  }
  .sinewave {
    background-image: url(../img/sinewave-70.png);
    background-repeat: no-repeat;
    background-position: 0px 30px;
    /*background-attachment: fixed;*/
    background-size: 100% 50%;
  }

  .heading-underline {
    height: 30px !important;
  }
  .para-title-ph {
    font-size: 20px !important;
  }
  .para-title-tb {
    margin-left: 26% !important;
  }
  .mr-l-tb {
    left: -5% !important;
    position: relative;
  }
  .btn-mr-tp {
    margin-top: 5% !important;
  }
  .sub-h-ph {
    font-size: 16px !important;
  }
  .text-ph {
    font-size: 14px !important;
  }
  .text-marg-ph {
    margin-left: 1% !important;
  }
  #brk {
    display: none !important;
  }
  .tp-btm {
    margin-top: -85% !important;
    padding-bottom: 30px !important;
  }
  .nav-menu .dropdown ul {
    left: -20px !important;
  }
  .ul-ph {
    margin-left: 20px !important;
  }
  .ul-ph > li {
    right: 5px !important;
  }
}
@media only screen and (max-device-width: 1366px) and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .section-mt-top {
    margin-top: -30% !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: portrait) {
  .section-mt-top {
    margin-top: -95% !important;
  }
  .p-text {
    margin-left: 3% !important;
    margin-top: 5% !important;
  }
  .para-title-tb {
    margin-left: 12% !important;
  }
  .heading-adj {
    height: 44px !important;
  }
  .para-title-ph {
    margin-bottom: -5% !important;
  }
  .para-mr {
    margin-top: 0px !important;
  }
  .btn-mr-tp {
    margin-top: 49px !important;
  }
  .para-f-size {
    font-size: 20px !important;
    height: 31px !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: landscape) {
  .nav-menu {
    margin-right: 0% !important;
  }
  .logo-phone {
    margin-left: 2% !important;
  }
  .section-mt-top {
    margin-top: -15% !important;
  }
}
