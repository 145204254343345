.container {
  display: flex !important;
  justify-content: center;
}

.left-address {
  padding: 10px 20px;
  border-radius: 8px;
  margin-right: 10px;
  width: 50%;
  height: 70vh;
  background-color: white;
  box-shadow: rgb(110 110 110 / 60%) 0px 0px 30px;
}


.label-control {
  width: 100%;
  padding: 0.35rem;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}

.applyBtn {
  /* margin-top: 1rem; */
  width: 120px;
  height: 30px;
  border-radius: 2px;
  background-color: blue;
  color: white;
}

.loginTable{
  padding: 2rem 1rem;
  width: 50%;
  justify-content: center;
  height: 75vh;
}

.btn-apply{
  margin-top: 1rem;
  width: 20%;
  height: 30px;
  border-radius: 2px;
  background-color: blue;
  color: white;
}