
body {
  /*    background-image:url(assets/img/service-background.jpg);*/
  display: block;
  position: relative;
  margin: 0 auto;
  z-index: 0;
  background-size: cover;
  background-attachment: fixed;
}
body::after {
  background: url('../img/about-bck.jpg');
  content: "";
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.carrd-body {
  padding: 1.85rem;
}
.form-control-a {
  border: 1px solid #ced4da;
  height: auto;
  display: block;
  width: 100%;
  padding-bottom: 47px !important;
  border-radius: 0;
  box-shadow: none;
  font-size: 17px;
}
.border-line {
  border-top: 5px solid #428bca;
}
.btn-border {
  border: 1px solid #428bca;
}

.quote-img {
  max-width: 10%;
  height: auto;
}
.quote-img-left {
  margin: 0 0 -46px -46px;
}
.quote-img-right {
  margin: -62px 0 0 582px;
}

.new-h2 {
  color: #fff;
  font-family: "Tenor Sans";
  /*margin-bottom: -45px;*/
  font-size: 64px !important;
  /*font-weight: 400;*/
  font-weight: bolder;
}
.btn-get {
  background-color: unset;
}
.btn-get:hover {
  background-color: #958677;
  color: rgb(241, 216, 192);
}
.heading-underline {
  border-bottom: 5px solid;
  width: max-content;
  height: 47px;
}
.form-title-ph {
  border-bottom: 33px solid #165994;
  width: 75%;
  height: 1px;
  margin-left: 13%;
  margin-top: 4%;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .partner-img {
    margin-top: 35px !important;
    font-size: 7vw !important;
  }
  .text-phone {
    font-size: 4vw !important;
  }
  .section-two {
    margin-top: -40% !important;
  }
  .contact .php-email-form input {
    padding: 2px;
  }
  .partner-img {
    margin-top: 25% !important;
    margin-bottom: -20% !important;
    font-size: 11vw !important;
  }
  .text-phone {
    font-size: 7vw !important;
  }
  .footer-ph {
    font-size: 13px;
    margin-top: 5px;
  }
  .logo-phone {
    margin-left: 55px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .submit-btn-phone {
    margin-right: 85px !important;
    margin-top: 30px !important;
  }
  .main-heading-ph {
    margin-bottom: -10px !important;
    font-size: 20px !important;
  }
  .text-pad {
    text-align: center !important;
    margin-top: 20% !important;
    font-size: 15px !important;
  }
  .ph-width {
    width: 90% !important;
  }
  .p-text > span {
    line-height: 1.4 !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .quote-img-left {
    margin: 0 0 -35px -10px;
  }
  .quote-img-right {
    margin: -60px 0 0 210px;
  }
  .quote-img {
    max-width: 25%;
    height: auto;
  }
  .text-phone {
    font-size: 17px !important;
  }
  .form-title-ph {
    font-size: 20px !important;
    border-bottom: 13px solid #165994 !important;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .logo-phone {
    margin-left: 33% !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .quote-img-left {
    margin: 0 0 -30px -5px !important;
  }
  .quote-img-right {
    margin: -60px 0 0 545px !important;
  }
  .new-h2 {
    font-size: 50px !important;
    margin-bottom: 30px !important;
  }
  .text-phone {
    font-size: 23px !important;
  }
  .section-two {
    margin-top: -55% !important;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .logo-phone {
    margin-left: -10px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .navbar-phone {
    margin-right: -35px !important;
  }
  .submit-btn-phone {
    margin-right: 155px !important;
    margin-top: 30px !important;
  }
  .section-two {
    margin-top: -80% !important;
  }
  .text-pad {
    margin-top: 20% !important;
  }
  .quote-img-left {
    margin: 0 0 -40px -30px !important;
  }
  .nav-menu {
    margin-right: -40px !important;
  }
  .nav-menu .dropdown ul {
    left: -20px !important;
  }
  .ul-ph {
    margin-left: 20px !important;
  }
  .ul-ph > li {
    right: 5px !important;
  }
}

@media only screen and (max-device-width: 1366px) and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .section-two {
    margin-top: -40% !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: portrait) {
  .section-two {
    margin-top: -72% !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: landscape) {
  .section-two {
    margin-top: -25% !important;
  }
  .nav-menu {
    margin-right: 0% !important;
  }
  .logo-phone {
    margin-left: 2% !important;
  }
}
