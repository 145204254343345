/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  margin-top: 15px;
  width: 100%;
  height: calc(100vh - 114px);
  padding: 0;
  overflow: hidden;
}

#hero .carousel-item {
  width: 100%;
  height: calc(100vh - 114px);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;
  overflow: hidden;
}
#hero .carousel-item::before {
  content: "";
  background-color: rgba(13, 30, 45, 0.1);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

#hero .carousel-content {
  text-align: left;
}

@media (max-width: 992px) {
  #hero,
  #hero .carousel-item {
    height: calc(100vh - 70px);
  }
  #hero .carousel-content.container {
    padding: 0 50px;
  }
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 74px;
  font-weight: 900;
}

#hero p {
  /*  width: 80%;*/
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-left,
#hero .carousel-inner .carousel-item-prev.carousel-item-right {
  /*opacity: 0.7;*/
  transition: 0.5s;
  opacity: 1;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .carousel-indicators li {
  cursor: pointer;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 17px;
  /* border-radius: 25px;*/
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 0;
  background: rgba(149, 134, 119, 70%);
  /*opacity: 0.7;*/
}

.btn-get-started {
  float: right ;
  font-family: "Mulish" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: 1px !important;
  display: inline-block !important;
  padding: 12px 32px !important;
  border-radius: 25px !important;
  transition: 0.5s !important;
  line-height: 1 !important;
  margin: 10px !important;
  color: #fff !important;
  -webkit-animation-delay: 0.8s !important;
  animation-delay: 0.8s !important;
  border: 0 !important;
  background: rgb(149, 134, 119) !important;
}

#hero .btn-get-started:hover {
  background: rgb(149, 134, 119) !important;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  /*#hero p {
      width: 60%;
    }*/
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 0px 0;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5c768d;
}

.section-title p {
  margin-bottom: 0;
}

.cardd {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background-clip: border-box;
  /* border: 1px solid rgba(0,0,0,.125); */
  border-radius: 0.25rem;
}

.text-style {
  color: #444444 !important;
  font-family: "Libre Baskerville";
  max-width: 67% !important;
  margin-top: 25% !important;
  margin-left: 14% !important;
  font-weight: 400 !important;
  font-size: 58px !important;
  white-space: nowrap !important;
}
