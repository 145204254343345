body {
  /*    background-image:url(assets/img/service-background.jpg);*/
  display: block;
  position: relative;
  margin: 0 auto;
  z-index: 0;
  background-size: cover;
  background-attachment: fixed;
}

body::after {
  background: url('../img//about-bck.jpg');
  content: "";
  /*opacity: 0.7;*/
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  background-repeat: round;
}

.border-white {
  text-align: center;
  border-style: solid;
  color: white;
  /*padding-top: 9%;
  padding-bottom: 5%;*/
  padding: 4%;
  width: 56%;
  /*margin-left: -5%;
  margin-right: -5%;*/
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.btn-get {
  background-color: unset;
}

.btn-get:hover {
  background-color: rgb(149, 134, 119);
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  border: 5px solid white;
  padding: 4%;
}
@media (max-width: 480px) {
  .aboout-ph {
    text-align: left !important;
  }
  .logo-phone {
    margin-left: 55px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .slider-headings-ph {
    font-size: 31px !important;
  }
  .para-title-ph {
    font-size: 20px !important;
    margin: auto;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .para-text-ph {
    font-size: 15px !important;
    text-align: center !important;
  }
  .sec-height {
    height: 480px !important;
  }
  .text-ph {
    font-size: 15px !important;
    text-align: center !important;
  }
  .sub-h-ph {
    font-size: 20px !important;
  }
  .icon-size {
    width: 20% !important;
    margin-bottom: -3% !important;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .logo-phone {
    margin-left: 33% !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
  .centered {
    padding: 2% !important;
  }
  .slider-headings-ph {
    margin-top: 10%;
    font-size: 23px !important;
  }
  .text-ph {
    font-size: 15px !important;
  }
  .section-margin-ph {
    margin-top: -55% !important;
  }
  .row-mr-l {
    margin-left: -1% !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .logo-phone {
    margin-left: -10px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .navbar-phone {
    margin-right: -35px !important;
  }
  .section-margin-ph {
    margin-top: -72% !important;
    margin-left: -8% !important;
  }
  .nav-menu .dropdown ul {
    left: -20px !important;
  }
  .ul-ph {
    margin-left: 20px !important;
  }
  .ul-ph > li {
    right: 5px !important;
  }
}

@media only screen and (max-device-width: 1366px) and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .section-margin-ph {
    margin-top: -30% !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: portrait) {
  .section-margin-ph {
    margin-top: -75% !important;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: landscape) {
  .nav-menu {
    margin-right: 0% !important;
  }
  .logo-phone {
    margin-left: 2% !important;
  }
  .section-margin-ph {
    margin-top: -15% !important;
  }
}
