/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services {
  padding-bottom: 30px;
}

.services .icon-box {
  margin-bottom: 20px;
  text-align: center;
}

.services .icon {
  display: flex;
  justify-content: center;
}

.services .icon i {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 50%;
  transition: 0.5s;
  color: #428bca;
  font-size: 40px;
  overflow: hidden;
  padding-top: 20px;
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.15);
}

.services .icon-box:hover .icon i {
  box-shadow: 0px 0 30px rgba(66, 139, 202, 0.5);
}

.services .title {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  position: relative;
  padding-bottom: 15px;
}

.services .title a {
  color: #444;
  transition: 0.3s;
}

.services .title a:hover {
  color: #428bca;
}

.services .title::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 2px;
  background: #428bca;
  bottom: 0;
  left: calc(50% - 25px);
}

.services .description {
  line-height: 24px;
  font-size: 14px;
}

body {
  /*    background-image:url(assets/img/service-background.jpg);*/
  display: block;
  position: relative;
  margin: 0 auto;
  z-index: 0;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: round;
}
body::after {
  background-repeat: round;
  background: url(../img/about-bck.jpg);
  content: "";
  opacity: 0.7;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.heading-underline {
  border-bottom: 5px solid;
  width: max-content;
  height: 47px;
}
.new-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 0.25rem;
}

.decor-text {
  text-align: center;
  font-size: 17px;
  font-family: "Mulish";
  font-weight: 300;
  color: rgb(68, 68, 68);
  line-height: 2;
}

.services-font {
  font-size: 25px;
  font-weight: bold;
}
.btn-get {
  background-color: unset;
}
.btn-get:hover {
  background-color: rgb(68, 68, 68);
  color: white;
}
.cardimg {
  flex-shrink: 0;
  width: 60%;
  margin: auto;
  display: block;
}


@media (max-width: 480px) {
  .logo-phone {
    margin-left: 50px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
    text-align: -webkit-center !important;
  }
  .text-ph {
    font-size: 15px !important;
    text-align: center !important;
  }
  .sub-h-ph {
    font-size: 20px !important;
  }
  .bottom-space {
    margin-bottom: 2px !important;
  }
  .bottom-space-2 {
    margin-bottom: -30px !important;
  }
  .heading-underline {
    height: 30px !important;
  }
  .cardimg {
    width: 30% !important;
  }
  /* .col-6-mr-top{
    margin-top: 10%;
  } */

  .cardd {
    padding-top: 0% !important;
  }
  .img-center {
    margin-left: 7% !important;
  }
  .decor-text {
    font-size: 15px !important;
    margin-top: 0px;
    margin-bottom: -15px;
    line-height: 1.5;
  }
  .service-top-mt {
    margin-top: -20%;
  }
  .footer-ph {
    font-size: 13px;
    margin-top: 5px;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .img-center {
    left: -15%;
    margin-right: 39px;
  }
  .cardimg {
    width: 40%;
  }
  .logo-phone {
    margin-left: 33% !important;
  }
  .aboout-ph {
    text-align: left !important;
  }
  .section-title {
    margin-left: 0px !important;
  }
  .b-2 {
    position: relative;
    top: -5px;
  }
  .b-3 {
    position: relative;
    top: -6px;
  }
  .ul-ph {
    margin-left: 17%;
  }
  .ul-ph > li {
    margin-bottom: -5%;
    position: relative;
    right: -15px;
    font-size: 15px;
  }
  .new-dd-ph {
    color: white;
    list-style: disc !important;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .logo-phone {
    margin-left: -10px !important;
  }
  .section-title-phone {
    margin-left: 0px !important;
  }
  .navbar-phone {
    margin-right: -35px !important;
  }
  .img-center {
    margin-left: 0% !important;
  }
  .btn {
    top: -3px;
    position: relative;
  }
  .btn-xtra {
    top: -8px;
    position: relative;
  }
  .nav-menu .dropdown ul {
    left: -20px !important;
  }
  .ul-ph {
    margin-left: 20px !important;
  }
  .ul-ph > li {
    right: 5px !important;
  }
}
@media only screen and (max-device-width: 1366px) and (min-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .row-a {
    position: relative !important;
    left: 0% !important;
  }

  .b-2 {
    position: relative;
    top: -3px;
  }
}

@media (min-device-width: 800px) and (max-device-width: 1200px) and (orientation: landscape) {
  .section-mr-top {
    margin-top: -10% !important;
  }
  .nav-menu {
    margin-right: 0% !important;
  }
  .logo-phone {
    margin-left: 2% !important;
  }
}
